import React from 'react'
import { Button } from 'antd'

import './ContactBar.css'

class ContactBar extends React.Component {
  openUrl = (url) => {
    window.open(url)
  }

  openBehance = () => {
    this.openUrl('https://behance.net/serchiscreative')
  }

  openInstagram = () => {
    this.openUrl('https://instagram.com/serchiscreative')
  }

  openFacebook = () => {
    this.openUrl('https://facebook.com/serchiscreative')
  }

  openLinkedin = () => {
    this.openUrl('https://linkedin.com/company/serchiscreative')
  }

  openMail = () => {
    this.openUrl('mailto:hello@serchis.com')
  }

  render () {
    return (
      <div className={'contact-wrapper'}>
        <Button className={'contact-button' } onClick={this.openMail} icon="mail" size={'large'} />
        <Button className={'contact-button' } onClick={this.openBehance} type="primary" icon="behance" size={'large'} />
        <Button className={'contact-button' } onClick={this.openInstagram} type="primary" icon="instagram" size={'large'} />
        <Button className={'contact-button' } onClick={this.openFacebook} type="primary" icon="facebook" size={'large'} />
        <Button className={'contact-button' } onClick={this.openLinkedin} type="primary" icon="linkedin" size={'large'} />
      </div>
    )
  }
}

export default ContactBar
