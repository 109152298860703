import React from 'react'
import ReactPageScroller from '../components/ReactPageScroller'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import 'antd/dist/antd.css'
import '../../static/full-page-scroll.css'
import Project from '../components/project/project'

class IndexPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: ''
    }
  }

  handlePageChange = (number, number2) => {
    const { currentPage } = this.state

    if (number2 || currentPage !== number) {
      this.updateDots(number)
      this.setState({ currentPage: number }) // set currentPage number, to reset it from the previous selected.
    }
  };

  updateDots = (i) => {
    const ul = document.getElementsByTagName('ul')[0]
    for (var j = 0; j < this.ul.childNodes.length; j++) {
      ul.childNodes[j].firstChild.className = this.updateClass(2, 'active', ul.childNodes[j].firstChild.className)
      if (j === i) {
        ul.childNodes[j].firstChild.className = this.updateClass(1, 'active', ul.childNodes[j].firstChild.className)
      }
    }
  }

  buildDots = () => {
    const projects = this.props.data.allContentfulProject.nodes.sort((x, y) => x.order - y.order)

    this.ul = document.createElement('ul')

    this.ul.className = this.updateClass(1, 'dots', this.ul.className)
    this.ul.className = this.updateClass(1, 'dots-right', this.ul.className)

    var _self = this

    for (var i = 0; i < projects.length; i++) {
      var li = document.createElement('li')
      var a = document.createElement('a')
      const obj = { i }

      a.onclick = () => {
        this.handlePageChange(obj.i)
        this.updateDots(obj.i)
        // const ul = document.getElementsByTagName('ul')[0]
        // for (var j = 0; j < this.ul.childNodes.length; j++) {
        //   ul.childNodes[j].firstChild.className = this.updateClass(2, 'active', ul.childNodes[j].firstChild.className)
        //   if (j === obj.i) {
        //     ul.childNodes[j].firstChild.className = this.updateClass(1, 'active', ul.childNodes[j].firstChild.className)
        //   }
        // }
      }
      li.appendChild(a)
      _self.ul.appendChild(li)
    }

    this.ul.childNodes[0].firstChild.className = this.updateClass(1, 'active', this.ul.childNodes[0].firstChild.className)

    document.body.appendChild(this.ul)
  }

  updateClass = (type, newClass, currentClass) => {
    if (type === 1) {
      return currentClass + ' ' + newClass
    } else if (type === 2) {
      return currentClass.replace(newClass, '')
    }
  }

  componentDidMount () {
    this.buildDots()
  }

  render () {
    const projects = this.props.data.allContentfulProject.nodes.sort((x, y) => x.order - y.order)

    return (
      <Layout onPageChange={this.handlePageChange}>
        <SEO title={'Serchis Creative - Design & Advertising'} keywords={['creative', 'agency', 'branding']} />
        <ReactPageScroller
          customPageNumber={this.state.currentPage}
          animationTimer={450}
          containerHeight={'100vh'}
          pageOnChange={(value1, value2) => this.handlePageChange(value1, value2)}
        >
          {projects.map((project) => {
            return (<section className="section" key={`project-section-${project.id}`} style={{ overflow: 'hidden', height: '100vh' }}>
              <Project key={`project-${project.id}`} project={project}></Project>
            </section>)
          })}
        </ReactPageScroller>
      </Layout>

    )
  }
}

export const query = graphql`
  query ProjectsPageQuery {
    allContentfulProject {
      totalCount
      nodes {
        date(formatString: "MMMM DD YYYY")
        id
        services
        slug
        tags
        testimonial
        title
        updatedAt
        node_locale
        country {
          file {
            url
          }
        }
        photo {
          file {
            url
          }
        }
        order
        description {
          id
          description
          internal {
            content
          }
        }
        externalLink
      }
    }
  }
`
export default IndexPage
