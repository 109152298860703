import React from 'react'
import moment from 'moment'
import ContactBar from '../ContactBar/ContactBar'
import TypingCarousel from '../TypingCarousel/TypingCarousel'
import './project.css'

class Project extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: ''
    }
  }

  getTypeWritterWords = () => {
    return [
      ' brand identity',
      ' brand naming',
      ' brand strategy',
      ' advertising',
      ' print & digital',
      ' stationery',
      ' signage',
      ' social media',
      ' editorial design',
      ' music artwork',
      ' web design',
      ' user interface',
      ' user experience',
      ' video production',
      ' 3D modelling',
      ' 3D animation',
      ' motion graphics'
    ]
  }

  getCurrentPageNumber = () => {
    const location = window.location.href
    const locationArray = location.split('#')
    const pageNumber = locationArray.length && locationArray[1]

    return pageNumber && (parseInt(pageNumber) + 1)
  }

  componentDidMount () {
    const number = this.getCurrentPageNumber()

    this.setState({ href: number })
  }
  // componentDidMount () {
  //   const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
  //   const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]' })(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification))

  //   const number = this.getCurrentPageNumber()

  //   this.setState({ isChrome, isSafari, href: number })
  // }

  componentDidUpdate () {
    const number = this.getCurrentPageNumber()

    if (this.state.href !== number) {
      this.setState({ href: number })
    }
  }

  isTall = () => {
    const { testimonial, slug } = this.props.project
    const isTestimonial = testimonial && testimonial.length
    const isContactPage = slug === 'get-in-touch'

    return isTestimonial || isContactPage
  }

  shouldDisplayAnimation = (orderNumber) => {
    const number = this.state.href

    return number === orderNumber
  }

  renderMobilePhotoContainer = () => {
    const { tags, photo } = this.props.project
    const displaySvg = tags === 'svg'
    const photoUrl = photo ? photo.file.url : null
    const isTall = this.isTall()
    const infoContainerClass = isTall ? 'mobile-photo-container mobile-photo-container--tall' : 'mobile-photo-container'
    const waveClass = isTall ? 'wave--mobile wave--mobile--tall' : 'wave--mobile'
    const svgClass = isTall ? 'svg svg--tall' : 'svg'

    return (
      <div className={infoContainerClass}>
        {displaySvg && <img className={svgClass} type="image/svg+xml" src="logo-safari.svg" />}
        {!displaySvg && <img className={'mobile-project__photo'} src={photoUrl}/>}
        <div className={waveClass}></div>
      </div>
    )
  }

  renderDesktopPhotoContainer = () => {
    const { tags, photo, country } = this.props.project
    const displaySvg = tags === 'svg'
    const photoUrl = photo && photo.file.url
    const countryUrl = country && country.file.url
    const projectPhotoClassName = countryUrl ? 'project__photo' : 'project__photo project__photo--wide'
    const waveContainerClassName = countryUrl ? 'wave__container' : 'wave__container wave__container--wide'

    return (
      <div className="photo-container" >
        {displaySvg && <img className="svg" type="image/svg+xml" src="logo-safari.svg" />}
        {!displaySvg && <img className={projectPhotoClassName} src={photoUrl}/>}
        <img className={waveContainerClassName} src="wave2.svg" ></img>
      </div>
    )
  }

  renderProjectDetails = (countryUrl) => {
    const { date } = this.props.project
    const momentDate = moment(date)
    const month = momentDate.format('MMM').toUpperCase()
    const year = `'${momentDate.format('YY')}`

    return (
      <div className={'details'}>
        <span className={'month'}>
          {month}
        </span>
        <span className={'day'}>
          {year}
        </span>
        <img className={'countryPhoto'} src={countryUrl}></img>
      </div>
    )
  }

  renderServices = (services) => {
    return (
      <div className={'services'}>
        {
          services.map((service, index) =>
            <div key={service} className={'service__child'}>
              {service}
              {index !== services.length - 1 && <span className={'serviceDivider'}>{' //'}</span>}
            </div>)
        }
      </div>
    )
  }

  renderDescription = (diplayTypewritter) => {
    const { description: projectDescription, testimonial } = this.props.project
    const typewritterWords = this.getTypeWritterWords()
    const description = projectDescription ? projectDescription.internal.content : null
    const isTestimonial = testimonial && testimonial.length
    const italicClass = isTestimonial ? 'italic' : ''
    const projectDescriptionClassName = 'project__description project__description--wide'

    return (
      <div className={projectDescriptionClassName + ' ' + italicClass}>
        {description}
        {diplayTypewritter &&
          <TypingCarousel
            toRotate={typewritterWords}
            period={'150'}
            pausePeriod={'850'}/>
        }
      </div>
    )
  }

  returnCallToActionButton = (countryUrl, isContactPage, externalLink) => {
    if (countryUrl) {
      return (
        <a target="_blank" href={externalLink} className={'viewProject'}>
          {'VIEW PROJECT'}
        </a>
      )
    }

    if (isContactPage) {
      return (
        <a target="_blank" href={externalLink} className={'viewProject'}>
          {'CONTACT US'}
        </a>
      )
    }
  }

  renderTestimonial = (description, testimonial) => {
    return (
      <div className={'testimonial-wrapper'}>
        <div className={'testimonial'}>{description}</div>
        <div className={'testimonial-author'}><b>{`- ${testimonial[0]}`}</b></div>
        <div className={'testimonial-author'}>{testimonial[1]}</div>
      </div>
    )
  }

  render () {
    const {
      services,
      title,
      tags,
      testimonial,
      externalLink,
      order,
      country,
      slug,
      description: projectDescription
    } = this.props.project

    const countryUrl = country && country.file.url
    const description = projectDescription && projectDescription.internal.content
    const isTestimonial = testimonial && testimonial.length
    const projectTitleClassName = countryUrl ? 'project__title' : 'project__title project__title--wide'
    const displaySvg = tags === 'svg'
    const isContactPage = slug === 'get-in-touch'
    const diplayTypewritter = order === 1

    // const displayAnimation = this.shouldDisplayAnimation(order)
    // const displayAnimation = true

    const infoContainerClass = this.isTall() ? 'info-container info-container--tall' : 'info-container'
    return (
      <React.Fragment>
        <div className={'wrapper-container component third-component'}>
          {this.renderMobilePhotoContainer(displaySvg)}
          <div className={infoContainerClass}>
            {countryUrl && this.renderProjectDetails(countryUrl)}
            {diplayTypewritter && <div className={'fill-space'}/>}
            <div className={projectTitleClassName}>{title}</div>
            {services && this.renderServices(services)}
            {!countryUrl && !isTestimonial && this.renderDescription(diplayTypewritter)}
            {isTestimonial && this.renderTestimonial(description, testimonial)}
            {this.returnCallToActionButton(countryUrl, isContactPage, externalLink)}
            {isContactPage && <ContactBar/>}
          </div>
          {this.renderDesktopPhotoContainer()}
        </div>

      </React.Fragment>
    )
  }
}

export default Project
