import React from 'react'
import './TypingCarousel.css'

class TypingCarousel extends React.Component {
  constructor (props) {
    super(props)
    const { toRotate, el, period, pausePeriod } = props
    this.pausePeriod = pausePeriod
    this.toRotate = toRotate
    this.el = el
    this.loopNum = 0
    this.period = parseInt(period, 10) || 2000
    this.txt = ''
    this.isDeleting = false
  }

  componentDidMount () {
    var elements = document.getElementsByClassName('txt-rotate')
    this.el = elements[0]
    this.tick()
  }

  tick = () => {
    var i = this.loopNum % this.toRotate.length
    var fullTxt = this.toRotate[i]

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1)
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1)
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>'

    var that = this
    var delta = this.period - Math.random() * 40

    if (this.isDeleting) { delta /= 2 }

    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.pausePeriod
      this.isDeleting = true
    } else if (this.isDeleting && this.txt === '') {
      this.isDeleting = false
      this.loopNum++
      delta = 500
    }

    setTimeout(function () {
      that.tick()
    }, delta)
  };

  render () {
    return (
      <span className="txt-rotate"></span>
    )
  }
}

export default TypingCarousel
